import { useEffect } from 'react';
import debounce from 'lodash/debounce';

export default (handleResizeEnd) => {
  useEffect(() => {
    const handleResize = debounce(handleResizeEnd, 100);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResizeEnd]);
};
