import React from 'react';
import BackgroundImg from 'gatsby-background-image';
import { useDrag } from 'react-use-gesture';
import css from 'src/theme/css';
import navigate from 'src/utils/navigate';
import Text from 'src/components/Text';
import ColorProvider from 'src/components/ColorProvider';
import Frame from 'src/components/Frame';
import CircleIcon, * as circleIcon from 'src/components/CircleIcon';
import Icon from 'src/components/IconPreset';
import { Grid } from 'src/components/FlexBox';

export default ({ title, image, brand, url, iconType }) => {
  const previewImageStack = [
    'linear-gradient(rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.2) 20%, rgba(0, 0, 0, 0.2) 40%, rgb(0, 0, 0) 100%)',
    image.fluid,
  ];

  const bind = useDrag(
    ({ tap }) => {
      if (tap) {
        navigate(url);
      }
    },
    { filterTaps: true }
  );

  return (
    <ColorProvider mode="dark">
      <BackgroundImg
        fluid={previewImageStack}
        {...bind()}
        css={css({
          my: 3,
          width: '100%',
          borderRadius: 2,
          overflow: 'hidden',
          transition: 'transform 1.2s cubic-bezier(0.19, 1, 0.22, 1)',
          transform: 'scale(1)',
          cursor: 'pointer',
          willChange: 'transform',
          '::before, ::after': {
            transform: 'scale(1.1)',
            transition:
              'transform 1.2s cubic-bezier(0.19, 1, 0.22, 1) !important',
          },
          'body:not(.touch) &:hover': {
            ...circleIcon.hoverStyles,
            transform: 'scale(1.05)',
            '::before, ::after': {
              transform: 'scale(1)',
            },
          },
        })}
      >
        <a
          href={url}
          onClick={(event) => {
            event.preventDefault();
          }}
          css={{
            textDecoration: 'none',
            pointerEvents: 'none',
            userSelect: 'none',
            userDrag: 'none',
          }}
        >
          <Grid
            gridTemplateRows="4rem auto auto auto"
            gridGap={3}
            alignItems="start"
            alignContent="start"
            justifyItems="start"
            css={css({
              p: 4,
              height: '100%',
            })}
          >
            <Icon {...brand} />
            <Frame ratio={2 / 3} css={{ width: '100%' }} />
            <CircleIcon type={iconType} />
            <Text variant="heading.m">{title}</Text>
          </Grid>
        </a>
      </BackgroundImg>
    </ColorProvider>
  );
};
