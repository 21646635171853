import React from 'react';
import { graphql } from 'gatsby';
import Card from './Card';

export default ({ title, previewImage, logo, slug }) => (
  <Card
    title={title}
    image={previewImage}
    brand={logo}
    url={`/${slug}/`}
    iconType="arrow"
  />
);

export const query = graphql`
  fragment SliderModuleEventFragment on ContentfulEvent {
    id
    title
    previewImage {
      fluid(maxWidth: 1000, quality: 80) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    logo {
      identifier
      media {
        file {
          url
        }
      }
    }
    slug
  }
`;
