import React, { useState } from 'react';
import { graphql } from 'gatsby';
import css from 'src/theme/css';
import get from 'lodash/get';
import clamp from 'lodash/clamp';
import range from 'lodash/range';
import { useSpring, animated } from 'react-spring';
import { useGesture } from 'react-use-gesture';
import useBreakpoints from 'src/hooks/useBreakpoints';
import useResizeEnd from 'src/hooks/useResizeEnd';
import useMeasure from 'react-use-measure';
import { ResizeObserver } from '@juggle/resize-observer';
import { Flex, Box, Grid } from 'src/components/FlexBox';
import ColorProvider from 'src/components/ColorProvider';
import SmartButton from 'src/components/SmartButton';
import Button from 'src/components/Button';
import RichText from 'src/components/RichText';
import ChevronRightIcon from 'src/assets/svg/ChevronRight';
import Article from './Article.js';
import Quote from './Quote.js';
import Event from './Event.js';
import Link from './Link.js';
import Placeholder from './Placeholder.js';
import ImageGroupCard from './ImageGroup.js';
import ImageGroup from 'src/components/ImageGroup';
import Layers from 'src/components/Layers';

const componentLookup = {
  ContentfulArticle: Article,
  ContentfulQuote: Quote,
  ContentfulEvent: Event,
  ContentfulLink: Link,
  ContentfulImageGroup: ImageGroupCard,
};

const columnLookup = {
  m: 3,
  s: 2,
};

export default ({
  text,
  items,
  colorMode,
  hasPlaceholder,
  shouldCollapseMargin,
  buttons,
  background,
}) => {
  const columnCount = useBreakpoints(columnLookup, 1);
  const [ref, { width }] = useMeasure({ polyfill: ResizeObserver });
  const w = Math.max(width, 100);
  const length = items.length + (hasPlaceholder ? 1 : 0);
  const columnGap = w * 0.03;
  const columnWidth = (w - columnGap * (columnCount - 1)) / columnCount;
  const itemWidth = columnWidth + columnGap;

  const [index, setIndex] = useState(0);
  const [spring, setSpring] = useSpring(() => ({ x: 0 }));
  const setIndexAndSpring = (i) => {
    setSpring({ x: i * itemWidth, immediate: false });
    setIndex(i);
  };

  const bind = useGesture(
    {
      onDrag: ({ movement: [x] }) => {
        setSpring({
          x: index * itemWidth + x,
          immediate: true,
        });
      },
      onDragEnd: ({ swipe: [swipeX] }) => {
        setIndexAndSpring(
          clamp(
            swipeX + Math.round(spring.x.value / itemWidth),
            columnCount - renderedItems.length,
            0
          )
        );
      },
    },
    { drag: { filterTaps: true } }
  );

  useResizeEnd(() => {
    setIndexAndSpring(0);
  });

  const interpolateOpacity = (i, min, max, spread) => (x) =>
    clamp(
      columnCount / 2 +
        0.5 +
        spread -
        Math.abs(columnCount / 2 - 0.5 - i - x / itemWidth),
      min,
      max
    );

  const renderedItems = (hasPlaceholder ? [...items, null] : items).map(
    (item, i) => {
      const opacity = spring.x.interpolate(interpolateOpacity(i, 0, 1, 0.3));
      const style = { opacity, display: 'flex' };
      if (item) {
        const Component = get(componentLookup, item.__typename);
        return (
          Component && (
            <animated.div key={item.id} style={style}>
              <Component {...item} />
            </animated.div>
          )
        );
      } else {
        return (
          <animated.div key="placeholder" style={style}>
            <Placeholder />
          </animated.div>
        );
      }
    }
  );

  const isStart = index >= 0;
  const isEnd = index <= columnCount - renderedItems.length;

  return (
    <ColorProvider mode={colorMode}>
      <Layers bg="antiMode">
        {background && <ImageGroup {...background} />}
        <Flex
          flexDirection="column"
          gy={5}
          css={css({
            position: 'relative',
            pt: shouldCollapseMargin ? 0 : 'sectionMargin',
            pb: 'sectionMargin',
            overflow: 'hidden',
          })}
        >
          {text && (
            <RichText
              alignItems="center"
              textAlign="center"
              {...text}
              css={css({ px: 'pageMargin' })}
            />
          )}

          <Flex flexDirection="column" gy={4}>
            <Box
              css={css({
                overflow: 'hidden',
                '> div': {
                  mx: 'auto',
                  px: 'pageMargin',
                  maxWidth: 'pageMaxWidth',
                  width: '100%',
                },
              })}
            >
              <animated.div
                {...bind()}
                style={{
                  transform: spring.x.interpolate((x) => `translateX(${x}px)`),
                  userSelect: 'none',
                }}
              >
                <div ref={ref}>
                  <Grid
                    gridAutoColumns="auto"
                    gridTemplateRows="auto"
                    gridAutoFlow="column"
                    gridGap={columnGap + 'px'}
                    css={css({
                      position: 'relative',
                      '> *': {
                        width: columnWidth + 'px',
                      },
                    })}
                  >
                    {renderedItems}
                  </Grid>
                </div>
              </animated.div>
            </Box>
            {length > columnCount && (
              <Flex alignSelf="center" alignItems="center">
                <Button
                  onClick={() => {
                    if (!isStart) {
                      setIndexAndSpring(index + 1);
                    }
                  }}
                >
                  <ChevronRightIcon
                    css={css({
                      size: 3,
                      m: 2,
                      fill: 'mode',
                      transform: 'scaleX(-1)',
                      transition: 'opacity 200ms ease-out',
                      opacity: isStart ? 0.3 : 1,
                    })}
                  />
                </Button>
                {range(length).map((i) => (
                  <animated.div
                    style={{
                      opacity: spring.x.interpolate(
                        interpolateOpacity(i, 0.3, 1, 0)
                      ),
                    }}
                  >
                    <Button
                      onClick={() => {
                        if (-i <= index - columnCount) {
                          setIndexAndSpring(columnCount - i - 1);
                        }
                        if (-i > index) {
                          setIndexAndSpring(-i);
                        }
                      }}
                    >
                      <Box
                        css={css({
                          bg: 'mode',
                          size: 2,
                          m: 2,
                          borderRadius: '100vw',
                        })}
                      />
                    </Button>
                  </animated.div>
                ))}
                <Button
                  onClick={() => {
                    if (!isEnd) {
                      setIndexAndSpring(index - 1);
                    }
                  }}
                >
                  <ChevronRightIcon
                    css={css({
                      size: 3,
                      m: 2,
                      fill: 'mode',
                      transition: 'opacity 200ms ease-out',
                      opacity: isEnd ? 0.3 : 1,
                    })}
                  />
                </Button>
              </Flex>
            )}
          </Flex>
          {buttons instanceof Array && (
            <Flex gx={4} gy={4} justifyContent="center">
              {buttons.map((button) => (
                <SmartButton key={button.id} {...button} />
              ))}
            </Flex>
          )}
        </Flex>
      </Layers>
    </ColorProvider>
  );
};

export const query = graphql`
  fragment SliderModuleFragment on ContentfulSliderModule {
    id
    colorMode
    hasPlaceholder
    text {
      json
    }
    items {
      ... on ContentfulArticle {
        ...SliderModuleArticleFragment
      }
      ... on ContentfulQuote {
        ...SliderModuleQuoteFragment
      }
      ... on ContentfulEvent {
        ...SliderModuleEventFragment
      }
      ... on ContentfulLink {
        ...SliderModuleLinkFragment
      }
      ... on ContentfulImageGroup {
        ...ImageGroupFragment
      }
    }
    buttons {
      ...ButtonFragment
    }
    background {
      ...ImageGroupFragment
    }
  }
`;
