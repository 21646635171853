import React from 'react';
import css from 'src/theme/css';
import ImageGroup from 'src/components/ImageGroup';

export default (props) => (
  <div
    css={css({
      overflow: 'hidden',
      borderRadius: 1,
    })}
  >
    <ImageGroup {...props} style={{ pointerEvents: 'none' }} />
  </div>
);
