import React from 'react';
import { graphql } from 'gatsby';
import Card from './Card';

export default ({ title, previewImage, brand, slug, videoUrl }) => (
  <Card
    title={title}
    image={previewImage}
    brand={brand}
    url={`/${slug}/`}
    iconType={videoUrl ? 'play' : 'text'}
  />
);

export const query = graphql`
  fragment SliderModuleArticleFragment on ContentfulArticle {
    id
    title
    videoUrl
    previewImage {
      fluid(maxWidth: 1000, quality: 80) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    brand {
      identifier
      media {
        file {
          url
        }
      }
    }
    slug
  }
`;
