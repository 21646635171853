// DEPRECATED

import useMediaQuery from 'src/hooks/useMediaQuery';
import { minWidth } from 'src/utils/breakpoints';
import get from 'lodash/get';

const keys = ['xl', 'l', 'm', 's', 'xs'];

export default (lookup, defaultValue) => {
  const matches = [
    useMediaQuery(minWidth.xl),
    useMediaQuery(minWidth.l),
    useMediaQuery(minWidth.m),
    useMediaQuery(minWidth.s),
    useMediaQuery(minWidth.xs),
  ];

  const matchedKey = keys.find((key, index) => {
    return Object.keys(lookup).includes(key) && matches[index];
  });

  return get(lookup, matchedKey || 'default', defaultValue);
};
