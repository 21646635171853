import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import css from 'src/theme/css';

import Text from 'src/components/Text';
import ColorProvider from 'src/components/ColorProvider';
import { Flex, Grid, Box } from 'src/components/FlexBox';

const blue = 'rgb(29, 61, 186)';
const magenta = 'rgb(180, 86, 238)';

export default ({ text, customerImage, customerDescription, customerName }) => {
  return (
    <Grid
      gridGap={5}
      gridTemplateRows="1fr auto"
      css={css({ mb: 3, userSelect: 'none' })}
    >
      <ColorProvider mode="dark">
        <Grid
          gridTemplateRows="auto 1fr auto"
          gridGap={4}
          css={css({
            position: 'relative',
            background: `linear-gradient(45deg, ${blue}, ${magenta})`,
            borderRadius: 2,
            p: 4,
          })}
        >
          <Text
            variant="heading.s"
            css={css({ fontWeight: 500, lineHeight: 1.25 })}
          >
            {text.text}
          </Text>
          <svg
            viewBox="0 0 40 40"
            style={{
              position: 'absolute',
              top: '100%',
              left: '1rem',
              width: '2rem',
              height: '2rem',
            }}
          >
            <path
              d="M23.685 362l5.825 20.138a5 5 0 008.231 2.25L61.508 362H23.685z"
              transform="translate(-435 -4456) translate(76 4094) translate(336)"
              stroke="none"
              fill="rgb(37, 62, 189)"
              fillRule="evenodd"
            />
          </svg>
        </Grid>
      </ColorProvider>
      <Flex gx={3}>
        {customerImage && (
          <Img
            {...customerImage}
            css={{ borderRadius: '100vw', flexShrink: 0 }}
          />
        )}
        <Box flexDirection="column">
          <Text>
            <b>{customerName}</b>
          </Text>
          <Text
            variant="small"
            css={css({ height: ['3.2em', null, null, '1.6em'] })}
          >
            {customerDescription}
          </Text>
        </Box>
      </Flex>
    </Grid>
  );
};

export const query = graphql`
  fragment SliderModuleQuoteFragment on ContentfulQuote {
    id
    customerName
    customerDescription
    customerImage {
      fixed(width: 60, height: 60) {
        ...GatsbyContentfulFixed_withWebp
      }
    }
    text {
      text
    }
  }
`;
