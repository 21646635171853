import React, { useContext } from 'react';
import css from 'src/theme/css';
import { Box } from 'src/components/FlexBox';
import Text from 'src/components/Text';
import { ThemeContext } from 'styled-components';

export default () => {
  const { isDark } = useContext(ThemeContext);
  return (
    <Box
      alignItems="center"
      justifyContent="center"
      css={css({
        background: ({ colors }) =>
          isDark
            ? `linear-gradient(to bottom, ${colors.black}, ${colors.gray800})`
            : `linear-gradient(to bottom, ${colors.gray100}, ${colors.white})`,
        border: 'solid 1px',
        borderColor: isDark ? 'gray600' : 'gray300',
        borderRadius: 2,
        my: 3,
      })}
    >
      <Text variant="heading.m" css={css({ color: 'gray400' })}>
        Coming Soon
      </Text>
    </Box>
  );
};
