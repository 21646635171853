import React from 'react';
import { graphql } from 'gatsby';
import Card from './Card';

export default ({ label, previewImage, url }) => (
  <Card title={label} image={previewImage} url={url} iconType="arrow" />
);

export const query = graphql`
  fragment SliderModuleLinkFragment on ContentfulLink {
    id
    label
    url
    previewImage {
      fluid(maxWidth: 1000, quality: 80) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
  }
`;
